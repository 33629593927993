@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Ephesis&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Caveat&family=Ceviche+One&family=Dancing+Script&family=Fruktur&family=Gloria+Hallelujah&family=Great+Vibes&family=Henny+Penny&family=Irish+Grover&family=Lobster&family=Permanent+Marker&family=Press+Start+2P&family=Qahiri&family=Righteous&family=Rubik+Beastly&family=Satisfy&family=Ubuntu&family=Zen+Antique&family=Zen+Kurenaido&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allerta+Stencil&family=Allura&family=Audiowide&family=Birthstone+Bounce&family=Bungee+Inline&family=Covered+By+Your+Grace&family=Creepster&family=Fleur+De+Leah&family=Fredericka+the+Great&family=Grechen+Fuemen&family=Herr+Von+Muellerhoff&family=Homemade+Apple&family=Kristi&family=Kumar+One+Outline&family=La+Belle+Aurore&family=Major+Mono+Display&family=Marck+Script&family=Monoton&family=Mr+Dafoe&family=Nanum+Brush+Script&family=Nothing+You+Could+Do&family=Pinyon+Script&family=Qwigley&family=Reenie+Beanie&family=Rock+Salt&family=Six+Caps&family=Titan+One&family=Waiting+for+the+Sunrise&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #EFEFEF transparent;
}


.no-inline {
  display: inherit !important;
}

/* ///////////////////////////////////////////////////////// */
.post {
  width: 60px;
  height: 64px;
}
.avatar {
  background-color: #ccc;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 100%;
  animation: shine-avatar 2s infinite linear;
}
.avatar1 {
  width: 60px;
  height: 64px;
}

.avatar + .line {
  margin-top: 11px;
  width: 100px;
}

@keyframes shine-avatar {
  0% {
    background-position: 0px;
  }
  40%,
  100% {
    background-position: 64px;
  }
}

/* /////////////////////////////////////////////////////////////// */
.sideBar-box {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}
/* /////////////////////////////////////////////////////////////// */
#root,
body,
html {
  height: 100%;
  margin: 0;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

*:focus {
  outline: none;
}
.sideBar-scroll-container-seeall {
  height: calc(100vh - 92px);
}
.sideBar-scroll-container {
  height: calc(100vh - 58px);
}
.sideBar-scroll-container-overlay {
  height: calc(100vh - 122px);
}
.sideBar-scroll-container-fontFamily {
  height: calc(100vh - 135px);
}
.main-canvas-container-open {
  width: calc(100vw - 467px);
}

.main-canvas-container-close {
  width: calc(100vw - 112px);
}

/* Removing from type number native arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.h-18 {
  height: 4.5rem;
}

.canvas-parent {
  height: calc(100vh - 210px) !important;
}

.canvas-container {
  margin: auto !important;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 600ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 600ms ease-in;
}

/* //////////////////////////////////////// */
@font-face {
  font-family: "Bison";
  src: url(./fonts/Bison.ttf);
}

/* /////////////////////////////////// */

/* cyrillic-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE2poGnedXvwjX-TmtJ9RI-.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE2poGnedXvwjX-TmJJ9RI-.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE2poGnedXvwjX-TmpJ9RI-.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE2poGnedXvwjX-TmVJ9RI-.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* hebrew */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE2poGnedXvwjX-TmRJ9RI-.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE2poGnedXvwjX-TmlJ9RI-.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE2poGnedXvwjX-TmhJ9RI-.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE2poGnedXvwjX-TmZJ9Q.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buEzpoGnedXvwjX-Rt1s4CAf_exL.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buEzpoGnedXvwjX-Rt1s4Ckf_exL.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buEzpoGnedXvwjX-Rt1s4CEf_exL.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buEzpoGnedXvwjX-Rt1s4C4f_exL.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* hebrew */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buEzpoGnedXvwjX-Rt1s4C8f_exL.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buEzpoGnedXvwjX-Rt1s4CIf_exL.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buEzpoGnedXvwjX-Rt1s4CMf_exL.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buEzpoGnedXvwjX-Rt1s4C0f_Q.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE4poGnedXvwjX2fmRR8Q.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE4poGnedXvwjX_fmRR8Q.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE4poGnedXvwjX3fmRR8Q.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE4poGnedXvwjX4fmRR8Q.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* hebrew */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE4poGnedXvwjX5fmRR8Q.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE4poGnedXvwjX0fmRR8Q.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE4poGnedXvwjX1fmRR8Q.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE4poGnedXvwjX7fmQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE1poGnedXvwj1AW3Fj0C8H-Q.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE1poGnedXvwj1AW3Fq0C8H-Q.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE1poGnedXvwj1AW3Fi0C8H-Q.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE1poGnedXvwj1AW3Ft0C8H-Q.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* hebrew */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE1poGnedXvwj1AW3Fs0C8H-Q.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE1poGnedXvwj1AW3Fh0C8H-Q.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE1poGnedXvwj1AW3Fg0C8H-Q.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v16/buE1poGnedXvwj1AW3Fu0C8.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
