.sliderVertical {
  -webkit-appearance: none;
  background: #c4c4c4;
  width: 175px;
  height: 2px;
  border-radius: 5px;
  outline: none;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sliderVertical::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #5f72bd;
}

.sliderVertical::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #5f72bd;
}
